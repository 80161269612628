<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الملف الشخصى</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my/"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">الملف الشخصى</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="properties-box">
                                <div class="inner-container">
                                    <div class="title">
                                        <h3>الملف الشخصى</h3>
                                    </div>
                                    <div class="profile-form">
                                        <div class="row">
                                            <div
                                                class="col-lg-4 col-md-12 col-sm-12"
                                            >
                                                <!-- Edit profile photo -->
                                                <div class="edit-profile-photo">
                                                    <img
                                                        :src="
                                                            userData.image ??
                                                            userData.image_url
                                                        "
                                                        onclick="document.getElementById('image').click()"
                                                        alt="profile-photo"
                                                    />
                                                    <div
                                                        class="change-photo-btn"
                                                    >
                                                        <div
                                                            class="photoUpload"
                                                        >
                                                            <span
                                                                ><i
                                                                    class="la la-cloud-upload"
                                                                ></i
                                                            ></span>
                                                            <input
                                                                type="file"
                                                                class="upload"
                                                                id="image"
                                                                @change="
                                                                    toBase64
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="col-lg-8 col-md-12 col-sm-12"
                                            >
                                                <vee-form
                                                    @submit="updateProfile"
                                                    :validation-schema="
                                                        ProfileFormValidation
                                                    "
                                                >
                                                    <div class="row">
                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label>الإسم</label>
                                                            <Field
                                                                v-model="
                                                                    userData.name
                                                                "
                                                                class="form-control"
                                                                name="name"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="name"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label
                                                                >الإيميل</label
                                                            >
                                                            <Field
                                                                v-model="
                                                                    userData.email
                                                                "
                                                                class="form-control"
                                                                name="email"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="email"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            id="mobile-group"
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label
                                                                >الموبايل</label
                                                            >

                                                            <Field
                                                                v-model="mobile"
                                                                class="form-control"
                                                                name="mobile"
                                                                id="mobile"
                                                                @blur="
                                                                    setMobile
                                                                "
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="mobile"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12 d-none"
                                                        >
                                                            <label
                                                                class="control-label"
                                                                >النوع</label
                                                            >
                                                            <Field
                                                                as="select"
                                                                v-model="
                                                                    userData.gender
                                                                "
                                                                class="form-control"
                                                                name="gender"
                                                            >
                                                                <option
                                                                    value="m"
                                                                >
                                                                    ذكر
                                                                </option>
                                                                <option
                                                                    value="f"
                                                                >
                                                                    أنثى
                                                                </option>
                                                            </Field>
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="gender"
                                                            />
                                                        </div>

                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label
                                                                >الدولة</label
                                                            >
                                                            <Field
                                                                v-model="
                                                                    userData.country
                                                                "
                                                                class="form-control"
                                                                name="country"
                                                                type="text"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="country"
                                                            />
                                                        </div>

                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label
                                                                >كلمة
                                                                المرور</label
                                                            >
                                                            <Field
                                                                v-model="
                                                                    userData.password
                                                                "
                                                                class="form-control"
                                                                name="password"
                                                                type="password"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="password"
                                                            />
                                                        </div>

                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label
                                                                class="control-label"
                                                                >نوع
                                                                الحساب</label
                                                            >
                                                            <Field
                                                                as="select"
                                                                v-model="
                                                                    userData.type
                                                                "
                                                                class="form-control"
                                                                name="type"
                                                            >
                                                                <option
                                                                    value="user"
                                                                >
                                                                    مستخدم
                                                                </option>
                                                                <option
                                                                    value="advertiser"
                                                                >
                                                                    معلن
                                                                </option>
                                                            </Field>
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="type"
                                                            />
                                                        </div>

                                                        <div
                                                            class="form-group col-lg-6 col-md-6 col-sm-12"
                                                        >
                                                            <label
                                                                >تأكيد كلمة
                                                                المرور</label
                                                            >
                                                            <Field
                                                                v-model="
                                                                    userData.password_confirmation
                                                                "
                                                                class="form-control"
                                                                name="password_confirmation"
                                                                type="password"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="password_confirmation"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            v-if="
                                                                userData.type !=
                                                                'user'
                                                            "
                                                            class="form-group col-lg-12 col-md-12 col-sm-12"
                                                        >
                                                            <label
                                                                >بيانات التحويل
                                                                البنكى</label
                                                            >
                                                            <Field
                                                                v-model="
                                                                    userData.transfer_data
                                                                "
                                                                class="form-control"
                                                                name="transfer_data"
                                                                as="textarea"
                                                            />
                                                            <ErrorMessage
                                                                class="has-error"
                                                                name="transfer_data"
                                                            />
                                                        </div>

                                                        <!-- Form Group -->
                                                        <div
                                                            class="form-group text-right col-lg-12 col-md-12 col-sm-12"
                                                        >
                                                            <button
                                                                type="submit"
                                                                class="theme-btn btn-style-one"
                                                            >
                                                                تعديل
                                                            </button>

                                                            <button
                                                                v-if="
                                                                    !userData.email_verified_at
                                                                "
                                                                type="button"
                                                                class="theme-btn btn-style-one bg-warning pull-left"
                                                                @click="
                                                                    resendVerificationEmail
                                                                "
                                                            >
                                                                إعادة إرسال
                                                                أيميل التفعيل
                                                            </button>
                                                        </div>
                                                    </div>
                                                </vee-form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import intlTelInput from "intl-tel-input";
import { Form, Field, ErrorMessage } from "vee-validate";
import ProfileFormValidation from "../../forms/profileFormValidation";

export default {
    name: "my.profile",
    components: {
        "vee-form": Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            userData: {},
            ProfileFormValidation,
            iti: null,
            mobile: null,
        };
    },
    mounted() {
        this.userData = { ...this.$store.state.userData };
        this.mobile = this.userData.mobile;
        this.initTelInput();
    },
    methods: {
        initTelInput(countryCode = "auto") {
            this.iti = intlTelInput(document.querySelector("#mobile"), {
                separateDialCode: true,
                utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
            });
            this.iti.setNumber(
                this.userData.country_code + "" + this.userData.mobile
            );

            document
                .querySelector("#mobile")
                .addEventListener("countrychange", () => {
                    this.setMobile();
                });
        },
        setMobile() {
            let countryData = this.iti.getSelectedCountryData();
            this.userData.country_code = "+" + countryData.dialCode;
            this.userData.country = countryData.name.replace(/\(.*\)/g, "");
            this.userData.mobile = this.iti.getNumber();
        },
        toBase64() {
            this.userData.image = document.querySelector("#image").files[0];

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(this.userData.image);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }).then((image) => {
                this.userData.image = image;
            });
        },
        updateProfile() {
            this.setMobile();

            this.$axios
                .post(
                    "/update-profile",
                    (({
                        name,
                        email,
                        password,
                        transfer_data,
                        image,
                        mobile,
                        country,
                        country_code,
                        gender,
                        type,
                    }) => {
                        let data = {
                            name,
                            email,
                            transfer_data,
                            image,
                            mobile,
                            country,
                            country_code,
                            gender,
                            type,
                        };

                        if (password && password.length) {
                            data.password = password;
                        }

                        return data;
                    })(this.userData)
                )
                .then(async (response) => {
                    let data = response.data;
                    delete data.message;
                    delete data._status;
                    delete data.updated_at;
                    await this.$store.dispatch("updateUserData", data);
                    this.userData = { ...this.$store.state.userData };
                });
        },
        resendVerificationEmail() {
            this.$store.dispatch("resendVerificationEmail");
        },
    },
};
</script>

<style>
#mobile-group .iti {
    display: block !important;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
    direction: ltr !important;
}
</style>

<style scoped>
@import "./../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

#mobile {
    direction: ltr;
}

.edit-profile-photo {
    height: auto;
}

.edit-profile-photo img {
    cursor: pointer;
}
</style>